
import { defineComponent, onMounted, ref } from 'vue'
import ApiService from '@/core/services/ApiService'
import { useRoute } from 'vue-router'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useI18n } from 'vue-i18n'

interface Video {
  title: string
  description: string
  streaming: string
}

interface Course {
  id: string
  title: string
  description: string
  videos: Video[]
}

export default defineComponent({
  name: 'test-saghartoon',
  components: {},
  setup() {
    const token = ref('')
    const loading = ref(true)
    const loadingVideo = ref(true)
    const videoURL = ref('')
    const selectedVideo = ref<Video | null>(null)
    const videoList = ref<Video[]>([])
    const route = useRoute()
    const courseId = route.params.id
    const course = ref<Course | null>(null)
    const showPlyr = ref(false)
    const { t } = useI18n()

    const refreshToken = async () => {
      await ApiService.get(`/sghartoon/token`)
        .then(({ data }) => {
          token.value = data.token
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const plyr = ref<any>(null)

    const selectVideo = async (streamId) => {
      showPlyr.value = false
      loadingVideo.value = true
      const video = (videoList.value as any).find(
        (v) => v.streaming === streamId
      )
      selectedVideo.value = video
      await refreshToken()
      videoURL.value = `https://webservices-test.sghartoon.com:4201/api/streaming/${streamId}/${token.value}`
      console.log(video)
      loadingVideo.value = false

      plyr.value.player.source = {
        type: 'video',
        title: video.title,
        sources: [
          {
            src: videoURL.value,
            type: 'video/mp4',
            size: 1080,
          },
        ],
      }
    }

    onMounted(async () => {
      loading.value = true
      await refreshToken()
      ApiService.get(`/sghartoon/list/${token.value}`)
        .then(({ data }) => {
          course.value = data.find((c: Course) => c.id === courseId)
          videoList.value = course.value?.videos as Video[]
          selectVideo(course.value?.videos[0].streaming)
          loading.value = false

          plyr.value.player.on('progress', () => {
            showPlyr.value = true
          })
          setCurrentPageBreadcrumbs(course.value?.title as string, [
            t('sghartoon.courseList'),
          ])
        })
        .catch((e) => {
          console.log(e)
        })
    })

    return {
      loading,
      loadingVideo,
      videoURL,
      selectedVideo,
      videoList,
      selectVideo,
      options: {},
      plyr,
      showPlyr,
      course,
    }
  },
})
